import * as React from 'react'
import { Container, Typography, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AtlasLogo } from './AtlasLayout/AtlasLogo'

export interface HuiLoaderProps {
  isLoading: boolean
  title?: string
  name: string
  type: 'Default' | 'Outage' | 'No Access'
  contactEmail?: string
  message?: string
}

export const HuiLoader: React.FC<HuiLoaderProps> = ({
  isLoading = false,
  title = 'ATLAS',
  name = 'Access Control Dashboard',
  type = 'Default',
  contactEmail = 'admin@mit.edu',
  message
}) => {
  const getContent: React.FC = () => {
    if (isLoading) return <></>
    if (message !== undefined) return <Typography variant='body1'>{message}</Typography>
    switch (type) {
      case 'Default':
        return <></>
      case 'No Access':
        return <Typography variant='body1'>{deniedMessage}<a href={`mailto:${contactEmail}`}>{contactEmail}</a></Typography>
      case 'Outage':
        return <Typography variant='h4'>{outageMessage}</Typography>
    }
  }

  const ContainerWrapper = styled(Container, { shouldForwardProp: () => true })<{type: string}>(({ theme }) => ({
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1.5,
    fontFamily: 'Open Sans',
    backgroundColor: type !== 'Default' ? '#343a40' : theme.palette.primary.main,
    'div.logo-container': {
      textAlign: 'center',
      color: '#fff',
      maxWidth: '12rem'
    },
    a: {
      color: '#E3124B',
      textDecoration: 'none'
    }
  }))
  const outageMessage: string = 'System is temporarily not available, please try again in 5 minutes.'
  const deniedMessage: string = 'You do not have access to ' + name + ' please contact '
  return (
    <ContainerWrapper type={type} maxWidth={false}>
      <div className='logo-container'>
        <div className='slide-in-blurred-top'>
          <AtlasLogo sx={{ minWidth: '5rem', marginRight: 'auto', marginLeft: 'auto' }} />
          <Typography variant='h1'>{title}</Typography>
          <Typography className='fade-in'>{name}</Typography>
          <br />
          {isLoading && <CircularProgress color='inherit' />}
          {getContent({})}
        </div>
      </div>
    </ContainerWrapper>
  )
}
